<template>
  <div class="pad-container">

    <!-- 上面展示活动图片 -->
    <div class='flx-col ali-c'>
      <img :src='picsPath((act_form.list)[6] ? (act_form.list)[6].pictureUrl : "")' class='img-zq mb-10'/>
      <div class="imgWrap">
          <div class='yxhd-wrapper flx-row ali-c'>
              <div class='yxhd-left'>
              <div class='yxhd-l-title fs-26 flx-row js-btw mt-10'>
                  <div class='yxhd-s'>分享砍价</div>
                  <div class='yxhd-s'>多人拼团</div>
              </div>
              <div class='yxhd-l-body flx-row js-btw'>
                  <div class='yxhd-s'>
                  <img :src='picsPath((act_form.list)[0] ? (act_form.list)[0].pictureUrl : "")' class='yxhd-img'/>
                  <div class='yxhd-text fs-26'>翻牌集卡</div>
                  <img :src='picsPath((act_form.list)[2] ? (act_form.list)[2].pictureUrl : "")' class='yxhd-img'/>
                  </div>
                  <div class='yxhd-s mr-10'>
                  <img :src='picsPath((act_form.list)[1] ? (act_form.list)[1].pictureUrl : "")' class='yxhd-img'/>
                  <div class='yxhd-text fs-26'>会员活动</div>
                  <img :src='picsPath((act_form.list)[3] ? (act_form.list)[3].pictureUrl : "")' class='yxhd-img' />
                  </div>
              </div>
              </div>
              <div class='yxhd-right'>
              <div class='yxhd-r-title mt-10'>
                  <div class='fs-26'>直播<span class='yxhd-text2 fs-20 c-9'>导购推荐</span></div>
              </div>
              <div class='yxhd-r-body'>
                  <img :src='picsPath((act_form.list)[4] ? (act_form.list)[4].pictureUrl : "")' class='yxhd-img' />
                  <div class='fs-26 yxhd-text'>优惠券<span class='yxhd-text2 fs-20 c-9'>购物无忧</span></div>
                  <img :src='picsPath((act_form.list)[5] ? (act_form.list)[5].pictureUrl : "")' class='yxhd-img'/>
              </div>
              </div>
          </div>
      </div>
      <img :src='picsPath((act_form.list)[7] ? (act_form.list)[7].pictureUrl : "")' class='img-zq mt-20'/>
      <div class="fs-16 mt-10 mb-10">首页展示提示</div>
    </div>

    <!-- 下面提交配置信息 -->
    <el-tabs v-model="activePart" @tab-click="changeTab" class="mt-20">
      <el-tab-pane
        :label="shop.storeName"
        :name="String(shop.id)"
        v-for="(shop,index) in shopList"
        :key="index"
      >
        <el-form
          class="flx-row ali-c flx-wr js-btw el-form mt-24"
          label-width="80px"
          :ref="'act_form'+(shop.id)"
          :model= "act_form"
        >
          <div v-for="(item,ind) in act_form.list" :key="ind" class="zone pad-container">
            <div class="ml-10 mb-20">{{item.type == 1 ? '分享砍价' : item.type == 2 ? '多人拼团' : item.type == 3 ? '翻牌集卡' : 
                item.type == 4 ? '会员活动' : item.type == 5 ? '直播' : item.type == 6 ? '优惠券' : item.type == 7 ? '推荐1区' :item.type == 8 ? '推荐2区':item.type == 9 ? '推荐3区' :''}}</div>
            <el-form-item label="图片上传" :prop="'list['+ind+'].pictureUrl'" :rules="[{message: '图片不能为空',required: true,trigger: 'blur'}]">
              <UploadPics
                :uploadType="'main'"
                :imgLimit="1"
                :imgSize="sizeList[ind].width+'*'+sizeList[ind].height"
                :imgsPreview="item.pictureUrl"
                @getImgData="(x,y,z)=>getImgData(x,y,z,ind)"
                :key="ind"
              />
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button class="form-button" type="primary" @click="submit">保 存</el-button>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import UploadPics from "@/components/UploadPics";
import { getStoreList } from "@/api/online_department_store/commodity_manage"
import { getZoneConfig, saveZoneConfig } from "@/api/config_center/miniprogram_marketingAct";

export default {
  components: { UploadPics },
  data() {
    return {
      img: "",
      dataurl: "",
      dialogVisible: false,
      activePart: "1",
      shopList: [],
      act_form:{
        list: [
        {
          type: 1,
          pictureUrl: ''
        },
        {
          type: 2,
          pictureUrl: ''
        },
        {
          type: 3,
          pictureUrl: ''
        },
        {
          type: 4,
          pictureUrl: ''
        },
        {
          type: 5,
          pictureUrl: ''
        },
        {
          type: 6,
          pictureUrl: ''
        },
        {
          type: 7,
          pictureUrl: ''
        },
        {
          type: 8,
          pictureUrl: ''
        },
        {
          type: 9,
          pictureUrl: ''
        }
      ]},
      sizeList: [
        {
          width: 150,
          height: 200
        },
        {
          width: 150,
          height: 200
        },
        {
          width: 150,
          height: 200
        },
        {
          width: 150,
          height: 200
        },
        {
          width: 300,
          height: 200
        },
        {
          width: 300,
          height: 200
        },
        {
          width: 700,
          height: 150
        },
        {
          width: 700,
          height: 180
        },
        {
          width: 700,
          height: 180
        }
      ],
      activities: [],
    };
  },
  mounted() {
    this.getStores();
  },
  methods: {
    //获取门店列表
    getStores() {
        getStoreList().then(res => {
            this.shopList = res.data.body.list;
            this.activePart = res.data.body.list[0].id + "";
            this.getConfig()
        })
    },
    getConfig() {
        getZoneConfig({ storeId: this.activePart })
        .then(res => {
          let data  = res.data.body.list;
          // console.log(data)
          if (data.length > 0) {
            data.forEach((item) => {
              this.$set(this.act_form.list, (parseInt(item.type) - 1), item)
            })
          }
        })
    },
    submit() {
      let key = "act_form" + this.activePart;
      let form = this.$refs[key][0];
      this.submitValid(form, this.doSubmit);
    },

    submitValid(form, cb) {
      form.validate(valid => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        } else {
          cb();
        }
      });
    },

    doSubmit() {
        // console.log(this.act_form)
        let data = JSON.parse(JSON.stringify(this.act_form.list))
        data = data.map(item => {
          return {
            storeId : this.activePart,
            type: item.type,
            pictureUrl: item.pictureUrl
          }
        });
        // console.log(data)
        saveZoneConfig(data).then(res => {
          this.$message.success("保存成功");
        });
    },
    // 获取图片数据
    getImgData(imgData, type, params, imgIndex) {
      // if (type == "main") {
        this.$set(this.act_form.list[imgIndex], 'pictureUrl', imgData.mainImg);
      // }
    },
    // 切换tab
    changeTab() {
      this.act_form.list.forEach(item => {
        item.pictureUrl = ""
      })
      this.getConfig()
    }
  }
};
</script>
<style scoped lang='scss'>
.imgWrap {
    width: 750px;
    margin: 0 auto;
    color: #666;
    font-weight: normal;
    font-size: 15px;
    .hd-wrapper{
        margin: 20px 25px;
        width: 700px;
        height: 826px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    .yxhd-wrapper{
        padding: 10px 0;
        margin: 0 20px;
        border-bottom: 1px solid #D8D8D8;
    }
    .yxhd-left, .yxhd-right{
        flex:1;
        box-sizing: border-box;
    }
    .yxhd-right{
        padding-left: 11px;
    }
    .yxhd-s{
        width: 150px;
    }
    .yxhd-img{
        border-radius: 5px;
        width: 100%;
        height: 200px;
        margin-top: 20px;
    }
    .yxhd-text{
        margin-top: 10px;
    }
    .yxhd-l-body{
        border-right: 1px solid #D8D8D8;
    }
    .yxhd-text2{
        margin-left: 10px;
    }
}
.info {
    text-align: center;
}
.el-form {
    padding: 0 10px 10px;
    box-sizing: border-box;
    width: 100%;
}
.zone {
    width: 48%;
    margin-top: 20px;
}
.capture {
    width: 300px;
}
.el-image {
    border-radius: 50%;
    width: 112px;
    height: 112px;
}
.img-zq{
    width: 720px;
    height: 150px;
    border-radius: 20px;
}
</style>