import request from '@/utils/request'

//获取营销活动及推荐1区、推荐2区配置信息
export function getZoneConfig (data) {
    return request({
        url: '/customer-service/lrPictureConfig/queryLrPictureConfig',
        method: 'post',
        data
    })
}

//提交营销活动及推荐1区、推荐2区配置信息
export function saveZoneConfig (data) {
    return request({
        url: '/customer-service/lrPictureConfig/saveLrPictureConfig',
        method: 'post',
        data
    })
}